"use client";

import { useRouter } from "next/navigation";

import {
  Button,
  Center,
  Container,
  Image,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";

import image from "../../public/image-404.svg";

const Custom404 = ({ content }: { content?: string }) => {
  const router = useRouter();
  return (
    <>
      <Container
        size="lg"
        className={
          "relative grid place-content-center items-center justify-center overflow-hidden xxs:min-h-[100vh] xxs:py-20 sm:min-h-[100vh] md:min-h-[80dvh] lg:min-h-[80dvh]"
        }
      >
        <Center>
          <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
            <Image
              src={image.src}
              alt="Not Found"
              className={
                "content-center items-center justify-center text-center"
              }
            />
            <div className="pt-12">
              <Title
                order={1}
                size={"h1"}
                textWrap="balance"
                className={"pb-5 font-black text-Color4"}
              >
                {content ? content : "هناك خطآ ما..."}
              </Title>
              <Text c="Color3" size="lg" className="justify-center">
                الصفحة التي تحاول فتحها غير موجودة. ربما أخطأت في كتابة العنوان،
                أو تم نقل الصفحة إلى عنوان آخر. إذا كنت تعتقد أن هذا خطأ، فاتصل
                بالدعم الفني للموقع.
              </Text>
              <Button
                size="md"
                variant="filled"
                color="Color3"
                mt="xl"
                className={`ml-3 xxs:w-full lg:w-auto`}
                onClick={() => router.back()}
              >
                الرجوع للخلف
              </Button>
              <Button
                size="md"
                variant="filled"
                color="Color4"
                mt="xl"
                className={`xxs:w-full lg:w-auto`}
                onClick={() => router.push("/")}
              >
                الصفحة الرئيسية
              </Button>
            </div>
          </SimpleGrid>
        </Center>
      </Container>
    </>
  );
};

export default Custom404;
